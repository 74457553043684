/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath
 */

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from './Banner';
import Nav from './NavToggle';
import FixedPanel from './FixedPanel';
import BasicSection from './BasicSection';
import ComposedSection from './ComposedSection';
import Screener from './Screener';
import Footer from './Footer';
import { makeTopLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function Layout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = makeTopLinks(cfg);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const sitedata = {
    sitename: cfg.sitename,
    siteurl: cfg.siteurl,
    language: cfg.language,
    homelink: cfg.homelink,
    sections: cfg.sections,
    footer: cfg.footer,
    patientFields: cfg.patientFields
  };
  const allsections = cfg.sections.map(sec => {
    return (sec.data ? <ComposedSection key={sec.id} src={sec} /> : <BasicSection key={sec.id} src={sec} />);
  });

  const imgPanel = <div key="imgPanel">
        <StaticImage src='../images/ctximg_1.jpg' alt="" className="mx-auto" />
  </div>;
  const imgPanel2 = <div key="imgPanel2">
    <StaticImage src='../images/ctximg_2.jpg' alt="" className="mx-auto" />
  </div>;
  allsections.splice(2, 0, imgPanel);
  allsections.splice(4, 0, imgPanel2);
  return (
    <div id="page">
      <div id="layout-root">
        <header>
          <Nav toplinks={toplinks}
               sitename={cfg.sitename}
               ctx={props.ctx}
               currentpath={props.currentpath}
               localizerfn={localized} />
          <FixedPanel src={cfg.fixedpanel} btnLabel={cfg.startstudy} visibleLower="banner" visibleUpper="screener" />
          <Banner title={cfg.title} subtitle={cfg.subtitle} startstudy={cfg.startstudy} banner={cfg.banner} />
        </header>
        <main>
          {allsections}
          <Screener config={cfg.screener} sitedata={sitedata} localizerfn={localized} />
        </main>
        {props.children}
        <Footer src={footdata} localizerfn={localized} />
      </div>
    </div>
  );
};
